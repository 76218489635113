import * as React from 'react';

import { EGDSPrimaryButton } from '@egds/react-core';
import { EGDSCard } from '@egds/react-core';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core';
import { EGDSLink } from '@egds/react-core';
import { EGDSSpacing } from '@egds/react-core';
import { EGDSHeading, EGDSText } from '@egds/react-core';
import { EGDSView } from '@egds/react-core';
import { RouteComponentProps } from 'react-router-dom';
import { CDN_URL } from 'src/constants';

const Landing: React.FC<RouteComponentProps> = () => (
  <main role="main" className="page-container" style={{ width: '100vw', height: '100vh', background: 'white' }}>
    <EGDSView className="page-container">
      <EGDSLayoutFlex alignItems="center" justifyContent="center">
        <EGDSLayoutFlexItem alignSelf="center">
          <img
            src={`${CDN_URL}images/EG_WordmarkLogo_Blue.jpg`}
            alt="Expedia Group Logo"
            style={{ width: '220.95px', height: 'auto' }}
          />
        </EGDSLayoutFlexItem>
      </EGDSLayoutFlex>
      <EGDSLayoutFlex alignItems="center" justifyContent="center">
        <EGDSLayoutFlexItem alignSelf="center">
          <EGDSSpacing>
            <EGDSCard padded>
              <EGDSHeading tag="h4" size={4}>
                Sign in to Expedia Group Ads Portal
              </EGDSHeading>
              <EGDSSpacing margin={{ block: 'six' }}>
                <EGDSLayoutFlex alignItems="center" justifyContent="center">
                  <EGDSLayoutFlexItem>
                    <EGDSPrimaryButton style={{ width: '100%' }} onClick={() => (window.location.href = '/home')}>
                      Continue
                    </EGDSPrimaryButton>
                  </EGDSLayoutFlexItem>
                </EGDSLayoutFlex>
              </EGDSSpacing>
              <EGDSLayoutFlex alignItems="center" justifyContent="center">
                <EGDSLayoutFlexItem alignSelf="center" style={{ fontSize: '11px' }}>
                  <EGDSText>By continuing, you have read and agree to the following:</EGDSText>
                </EGDSLayoutFlexItem>
              </EGDSLayoutFlex>
              <EGDSLayoutFlex alignItems="center" justifyContent="center">
                <EGDSLayoutFlexItem alignSelf="center">
                  <EGDSLink>
                    <a
                      href="https://legal.expediagroup.com/privacy/privacy-and-cookies-statements/partner-sites/consolidated-ad-portal"
                      style={{ justifyContent: 'center', fontSize: '11px', color: 'blue' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Statement
                    </a>
                  </EGDSLink>
                </EGDSLayoutFlexItem>
              </EGDSLayoutFlex>
              <EGDSLayoutFlex alignItems="center" justifyContent="center">
                <EGDSLayoutFlexItem alignSelf="center">
                  <EGDSLink>
                    <a
                      href="https://legal.expediagroup.com/privacy/privacy-and-cookies-statements/partner-sites1/consolidated-ad-portal1"
                      style={{ justifyContent: 'center', fontSize: '11px', color: 'blue' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Cookie Statement
                    </a>
                  </EGDSLink>
                </EGDSLayoutFlexItem>
              </EGDSLayoutFlex>
            </EGDSCard>
          </EGDSSpacing>
        </EGDSLayoutFlexItem>
      </EGDSLayoutFlex>
    </EGDSView>
  </main>
);

Landing.displayName = 'Landing';

export default Landing;
